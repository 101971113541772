import React from 'react';

import Head from '../../components/Head';
import TodoSubList from '../../components/TodoSubList';

import '../../style/_common.scss';

class TodoPage extends React.Component {
  render() {
    const title = 'The Six Types of Insurance you Should Have for Your DPC Practice';
    const desc = `Yes, even DPC practices need insurance, irony be damned. Here's the types of insurance you should have in place before you open the doors of your direct primary care practice.`;
    return (
      <TodoSubList title={title} desc={desc} id="insurance">
        <Head title={title} description={desc} url="/todo/insurance/" image="/og_image.jpg" />
      </TodoSubList>
    );
  }
}

export default TodoPage;
